import { Components, Theme } from "@mui/material/styles";
import type {} from "@mui/x-date-pickers/themeAugmentation";

const Calendar = (theme: Theme): Components<Theme> => {
  return {
    MuiDayCalendar: {
      styleOverrides: {
        weekDayLabel: {
          color: theme.palette.mode === "light" ? "#071224" : "#ffff",
          fontSize: "1.6rem",
        },
        header: {
          justifyContent: "space-between",
        },
        weekContainer: {
          justifyContent: "space-between",
        },
        monthContainer: {
          position: "unset",
        },
        slideTransition: {
          minHeight: "unset",
        },
        root: {
          height: "100%",
        },
      },
    },

    MuiDateTimePicker: {
      defaultProps: {
        slotProps: {
          textField: {
            InputLabelProps: {
              color: "labelPrimary",
            },
          },
        },
      },
    },

    MuiDateCalendar: {
      styleOverrides: {
        root: {
          width: "100%",
          height: "34rem",
          maxHeight: "unset",
        },
        viewTransitionContainer: {
          height: "100%",
          "& > div": {
            height: "100%",
          },
        },
      },
    },

    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontSize: "1.6rem",
        },
      },
    },

    MuiPickersLayout: {
      styleOverrides: {
        contentWrapper: {
          padding: "2rem 2rem 2rem 2rem",

          "& > div": {
            margin: 0,
          },

          "& > hr": {
            display: "none",
          },
        },
      },
    },

    MuiPickersCalendarHeader: {
      styleOverrides: {
        labelContainer: {
          background: "#071224",
          borderRadius: "15rem",
          padding: ".3rem 0 .3rem 1.5rem",
          minHeight: "35px",
          maxHeight: "35px",
          marginTop: "3rem",
        },
        label: {
          color: "#ffff",
        },
        root: {
          minHeight: "35px",
          maxHeight: "35px",
          paddingLeft: 0,
          paddingRight: 0,
          marginTop: 0,
          marginBottom: "4rem",
        },
        switchViewIcon: {
          color: "#ffff",
        },
      },
    },

    MuiMonthCalendar: {
      styleOverrides: {
        root: {
          width: "100%",
          height: "100%",
        },
      },
    },

    MuiPickersMonth: {
      styleOverrides: {
        monthButton: {
          fontSize: "1.8rem",
        },
      },
    },

    MuiYearCalendar: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },

    MuiPickersYear: {
      styleOverrides: {
        yearButton: {
          fontSize: "1.6rem",
        },
      },
    },

    MuiPickersArrowSwitcher: {
      styleOverrides: {
        root: {
          marginTop: "3rem",
        },
      },
    },
  };
};

export default Calendar;
